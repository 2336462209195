require('dotenv').config()

import Vue from 'vue'


// Router
import router from './router'


// Vuex
import store from './store'


// Plugins
import './plugins/bootstrap-vue'
import './plugins/axios'
import './plugins/vuelidate'
import './plugins/tailwind'


// App Component
import App from './App.vue'






// Initialize Vue Application
Vue.config.productionTip = false


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
