<template>
  <div
    id="app"
    class="min-vh-100 flex flex-column w-full"
  >
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "@builder/assets/scss/main.scss";

[data-lastpass-root] {
  transform: translateX(100%);
}
</style>
