import store from '../store'
import Login from '../views/login/Login'
import ResetPassword from '../views/login/ResetPassword'


export default [
  {
    path: '',
    name: 'dashboard',
    beforeEnter (to, from, next) {
      if(to.query.redirect != undefined){
        return next({path:to.query.redirect})
      }else{
        next({path: '/login'})
      }
    }
  },
  {
    path: '/login',
    name:'login',
    component: Login,
    beforeEnter (to, from, next) {
      if(to.query.invalidToken != undefined){
        store.dispatch('auth/signOut')
        return next()
      }
      next()
    }
  },
  {
    path: '/logout',
    name:'logout',
    beforeEnter (to, from, next) {
      store.dispatch('auth/signOut').finally(()=>{
        return next('login')
      })
     
    }
  },
  {
    path: '/reset-password-form',
    name:'reset-password',
    component: ResetPassword,
    beforeEnter (to, from, next) {
      next()
    }
  }
]
