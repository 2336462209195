import Cookie from 'js-cookie'

const state = () => ({
  authenticated: false,
  user: null
})

const getters = {
  authenticated (state) {
    return state.authenticated
  },

  user (state) {
    return state.user
  }
}

const actions = {
  async signIn (_, credentials) {
    await axios.post('api/auth/login', credentials)
      .then(({data}) => {
        console.log(process.env.VUE_APP_MAIN_COOKIE_DOMAIN)
        Cookie.set('cross_estage_token', data.access_token, { domain: process.env.VUE_APP_MAIN_COOKIE_DOMAIN,sameSite: 'None',secure:true })
      })
  },

  async forget (_, payload) {
    return await axios.post('api/auth/password/forget', payload)
  },

  async reset (_, payload) {
    return await axios.post('api/auth/password/reset', {
      ...payload,
      token: new URLSearchParams(window.location.search).get('token')
    })
  },


  async signOut ({commit}) {
    Cookie.remove('cross_estage_token')
    await axios.get('api/auth/logout')
      .then(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
  }

}

const mutations = {
  SET_AUTHENTICATED (state, paylaod) {
    state.authenticated = paylaod
  },

  SET_USER (state, payload) {
    state.user = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
